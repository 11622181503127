import React from "react"

import { videoWrapper } from "../styles/youtube.module.css"

export default function Youtube({ src, title }) {
  return (
    <div className={videoWrapper}>
      <iframe
        frameBorder="0"
        width="100%"
        height="100%"
        title={title}
        src={`${src}?controls=0&modestbranding=1&rel=0`}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </div>
  )
}
