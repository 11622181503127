import { graphql, navigate } from "gatsby"
import React, { useState, useEffect } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from "../components/Layout"
import Youtube from "../components/Youtube"
import useMediaQuery from "../hooks/useMediaQuery"

import {
  grid,
  column,
  item,
  gridHeader,
  detailImages,
  detailImagesSingle,
  copy,
  link,
} from "../styles/detail.module.css"

export default function Detail({ data }) {
  const isMobile = useMediaQuery("(max-width: 900px)")

  const project = data.contentfulProject

  console.log("project: ", project)

  const images = project?.images ?? []
  const description = project.description
    ? project.description.childMarkdownRemark.html
    : ""
  const title = project.title

  const video = project.youtube

  const [column1, setColumn1] = useState([])
  const [column2, setColumn2] = useState([])

  useEffect(() => {
    images.forEach((image, index) => {
      if (image) {
        const i = index + 2
        if (i % 2 === 0) {
          setColumn1(column1Images => [...column1Images, image])
        } else {
          setColumn2(column2Images => [...column2Images, image])
        }
      }
    })
  }, [])

  const goBack = () => {
    navigate(-1)
  }

  function isOneImageProject(images) {
    return images.length === 1
  }

  function getTwoColumnLayout() {
    return (
      <section className={detailImages}>
        <div className={column}>
          {video && <Youtube src={video} title={title} />}

          {column1.map(image => {
            return (
              <GatsbyImage
                className={item}
                image={getImage(image)}
                alt={image.filename}
                key={`col1-${image.filename}`}
              />
            )
          })}
        </div>

        <div className={column}>
          {column2.map(image => {
            return (
              <GatsbyImage
                className={item}
                image={getImage(image)}
                alt={image.filename}
                key={`col2-${image.filename}`}
              />
            )
          })}
        </div>
      </section>
    )
  }

  function getOneColumnLayout() {
    return (
      <section className={`${detailImages} ${detailImagesSingle}`}>
        {video && <Youtube src={video} title={title} />}

        {images.map((image, index) => {
          return (
            <GatsbyImage
              className={item}
              image={getImage(image)}
              alt={image.filename}
              key={`single-col-${image.filename}-${index}`}
            />
          )
        })}
      </section>
    )
  }

  function renderLayout() {
    if (isMobile || isOneImageProject(images)) {
      return getOneColumnLayout()
    }
    return getTwoColumnLayout()
  }

  return (
    <Layout>
      <section className={`${grid} container`}>
        <header className={gridHeader}>
          <button onClick={goBack} className={link}>
            Back
          </button>
          <h1>{title}</h1>
        </header>
        {renderLayout()}

        <div
          className={copy}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </section>
    </Layout>
  )
}

export const query = graphql`
  query DetailPageContentful($slug: String) {
    contentfulProject(slug: { eq: $slug }) {
      description {
        childMarkdownRemark {
          html
        }
      }
      id
      images {
        gatsbyImageData
        filename
      }
      title
      youtube
    }
  }
`
